import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  const createCarousel = () => {
    const entries = SmarteditController.getEditorialEntries(
      '.js-ken-attachments-slider',
    );

    Array.from(entries).forEach(async item => {
      const nextEl = item.querySelector('.swiper-button-next');
      const prevEl = item.querySelector('.swiper-button-prev');
      const scrollbar = item.querySelector('.swiper-scrollbar');

      const LazySwiperLib = await LazySwiperController();
      return new LazySwiperLib(item, {
        spaceBetween: 30,
        slidesPerView: 'auto',
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 30,
        scrollbar: {
          el: scrollbar,
          hide: false,
        },
        navigation: { nextEl, prevEl },
        breakpointsInverse: true, // swiper version 4
        breakpoints: {
          767: {
            spaceBetween: 10,
            slidesOffsetBefore: 120,
            slidesOffsetAfter: 10,
          },
        },
      });
    });
  };

  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};
